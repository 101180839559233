<script>
const propName = "value", eventName = "input"
export default {
  name: "InputSlider",
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    savedValue: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    maxValue: {
      type: String,
      default: "",
    },
    minValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    fieldId: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None") {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  }
}
</script>

<template>
  <v-slider
    v-model="model"
    :min="minValue"
    :max="maxValue"
    :hint="hint"
    :label="label"
    :step="5000"
    inverse-label
    thumb-size="50"
    thumb-label="always"
    class="mt-15"
  />
</template>